import Swal from "sweetalert2";
import vueI18n from "@/core/plugins/vue-i18n";
const SwalService = {
  messageTypes: ["success", "warning", "info", "error"],
  titles: {
    updated: vueI18n.t("updated"),
    created: vueI18n.t("created"),
    error: vueI18n.t("error"),
    warning: vueI18n.t("warning"),
    success: vueI18n.t("success"),
    modified: vueI18n.t("modified"),
    are_you_sure: vueI18n.t("are_you_sure"),
    deleted: vueI18n.t("deleted"),
    imported: vueI18n.t("imported"),
    enabled: vueI18n.t("enabled"),
  },
  messages: {
    added: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_added", { data }),
    created: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_created", { data }),
    updated: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_updated", { data }),
    deleted: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_deleted", { data }),
    filter_is_required: (data) => vueI18n.t("filter_is_required", { data }),
    data_is_invalid: vueI18n.t("data_is_invalid"),
    please_select_to_export: vueI18n.t(
      "please_select_at_least_one_column_to_export"
    ),
    successifully_completed: vueI18n.t("successifully_completed"),
    please_select_at_least_one_filter: vueI18n.t(
      "please_select_at_least_one_filter"
    ),
    you_wont_be_able_to_revert_this: vueI18n.t(
      "you_wont_be_able_to_revert_this"
    ),
    yes_delete_it: vueI18n.t("yes_delete_it"),
    date_is_required: vueI18n.t("date_is_required"),
    no: vueI18n.t("no"),
    something_went_wrong: vueI18n.t("something_went_wrong"),
  },
  successMessage(
    data = {
      title: this.titles.success,
      html: "",
      icon: this.messageTypes[0],
      timer: 2500,
      showConfirmButton: false,
    }
  ) {
    Swal.fire({
      title: this.titles.success,
      html: "",
      icon: this.messageTypes[0],
      timer: 2500,
      showConfirmButton: false,
      ...data,
    });
  },
  errorMessage(
    data = {
      title: this.titles.error,
      html: "",
      icon: this.messageTypes[3],
      timer: 2500,
      showConfirmButton: false,
    }
  ) {
    Swal.fire({
      title: this.titles.error,
      html: "",
      icon: this.messageTypes[3],
      timer: 2500,
      showConfirmButton: false,
      ...data,
    });
  },
  warningMessage(
    data = {
      title: this.titles.warning,
      html: "",
      icon: this.messageTypes[1],
      showConfirmButton: false,
      timer: 2500,
    }
  ) {
    Swal.fire({
      title: this.titles.warning,
      html: "",
      icon: this.messageTypes[1],
      showConfirmButton: false,
      timer: 2500,
      ...data,
    });
  },
  infoMessage(
    data = {
      title: this.titles.warning,
      html: "",
      icon: this.messageTypes[2],
      showConfirmButton: false,
      timer: 2500,
    }
  ) {
    Swal.fire({
      title: this.titles.warning,
      html: "",
      icon: this.messageTypes[2],
      showConfirmButton: false,
      timer: 2500,
      ...data,
    });
  },
  async warningConditionMessage(
    data = {
      title: this.titles.are_you_sure,
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: vueI18n.t("yes"),
      cancelButtonText: vueI18n.t("no"),
    },
    confirmed = () => {},
    reject = () => {}
  ) {
    await Swal.fire({
      title: this.titles.are_you_sure,
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: vueI18n.t("yes"),
      cancelButtonText: vueI18n.t("no"),
      ...data,
    }).then((result) => {
      if (result.isConfirmed) {
        confirmed();
      } else {
        reject();
      }
    });
  },
};

export default SwalService;
